import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";
import { isEmail } from "validator";

import AuthService from "../services/auth.service";

import { withRouter } from "../common/with-router";

const required = (value) => {
	if (!value) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				This field is required.
			</div>
		);
	}
};

const email = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				Please use a valid email address.
			</div>
		);
	}
};

class Login extends Component {
	constructor(props) {
		super(props);
		this.handleLogin = this.handleLogin.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);

		this.state = {
			email: "",
			password: "",
			loading: false,
			message: "",
		};
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value,
		});
	}

	onChangePassword(e) {
		this.setState({
			password: e.target.value,
		});
	}

	handleLogin(e) {
		e.preventDefault();

		this.setState({
			message: "",
			loading: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			AuthService.login(this.state.email, this.state.password).then(
				() => {
					this.props.router.navigate("/profile");
					window.location.reload();
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.error &&
							error.response.data.error[0]) ||
						error.message ||
						error.toString();

					this.setState({
						loading: false,
						message: resMessage,
					});
				}
			);
		} else {
			this.setState({
				loading: false,
			});
		}
	}

	render() {
		return (
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-10 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
						Sign in
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<Form
						className="space-y-6"
						onSubmit={this.handleLogin}
						ref={(c) => {
							this.form = c;
						}}
					>
						<label
							htmlFor="email"
							className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
						>
							Email address
						</label>
						<div className="mt-2">
							<Input
								type="email"
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								name="email"
								autoComplete="email"
								value={this.state.email}
								onChange={this.onChangeEmail}
								validations={[required, email]}
							/>
						</div>

						<div className="flex items-center justify-between">
							<label
								htmlFor="password"
								className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
							>
								Your password
							</label>
							<div className="text-sm">
								<Link
									to={"/forgot-password"}
									className="font-semibold text-indigo-600 hover:text-indigo-500"
								>
									Forgot password?
								</Link>
							</div>
						</div>
						<div className="mt-2">
							<Input
								type="password"
								className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
								name="password"
								autoComplete="current-password"
								value={this.state.password}
								onChange={this.onChangePassword}
								validations={[required]}
							/>
						</div>

						<button
							type="submit"
							disabled={this.state.loading}
							className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 px-5 py-2.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Sign in
							{this.state.loading && (
								<span
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>
							)}
						</button>

						{this.state.message && (
							<div className="form-group">
								<div className="mt-2 text-sm text-red-600" role="alert">
									<li>{this.state.message}</li>
								</div>
							</div>
						)}

						<CheckButton
							style={{ display: "none" }}
							ref={(c) => {
								this.checkBtn = c;
							}}
						/>
					</Form>
				</div>
			</div>
		);
	}
}

export default withRouter(Login);
