import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import Login from "./components/login.component";
import Register from "./components/register.component";
import ForgotPassword from "./components/forgot-password.component";
import Home from "./components/home.component";
import Header from "./components/home-header.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

class App extends Component {
	render() {
		return (
			<div className="App">
				<Header />
				<div className="md:container md:mx-auto">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/user" element={<BoardUser />} />
						<Route path="/mod" element={<BoardModerator />} />
						<Route path="/admin" element={<BoardAdmin />} />
					</Routes>
				</div>
			</div>
		);
	}
}

export default App;
