import axios from "axios";

const API_URL = "https://api.deliv2me.com/api/account/";

class AuthService {
	async login(email, password) {
		const response = await axios.post(API_URL + "login", {
			email,
			password,
		});

		if (response.data.accessToken) {
			localStorage.setItem("user", JSON.stringify(response.data));
		}
		return response.data;
	}

	logout() {
		localStorage.removeItem("user");
	}

	register(fullname, email, password, confirmpassword) {
		return axios.post(API_URL + "register", {
			fullname,
			email,
			password,
			confirmpassword,
		});
	}

	reset(email) {
		return axios.post(API_URL + "forgot-password", {
			email,
		});
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem("user"));
	}
}

export default new AuthService();
