import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://api.deliv2me.com/api/";

class UserService {
	getWidgetsZones() {
		return axios.get(API_URL + "shop-widgets/zones");
	}

	getWidgetsTypes() {
		return axios.get(API_URL + "shop-widgets/types");
	}

	getWidgetsInstances() {
		return axios.get(API_URL + "shop-widgets/instances");
	}

	getWidgetDetails(TypeId, ZoneId, id) {
		return axios.post("shop-widgets/details", { TypeId, ZoneId, id });
	}

	getUserBoard() {
		return axios.get(API_URL + "user", { headers: authHeader() });
	}

	getModeratorBoard() {
		return axios.get(API_URL + "mod", { headers: authHeader() });
	}

	getAdminBoard() {
		return axios.get(API_URL + "admin", { headers: authHeader() });
	}
}

export default new UserService();
